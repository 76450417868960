.blogs-page {
  max-width: 800px;
  align-content: center;
}

.blogs-page-header {
  font-family: "Bigshot One";
  text-align: center;
  font-size: x-large;
}

.blogs-page-content {
  padding: 20px;
  text-align: center;
  background-color: wheat;
  border-radius: 25px;
}

.blogs-page-content:hover {
  background-color: bisque;
}

a {
  text-decoration: none;
  color: black;
}
