.clients-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Bigshot One";
  width: 90vw;
}

.clients-page-header {
  font-size: x-large;
}

.clients-page-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 90%;
}

.clients-page-content {
  max-width: 75px;
  text-align: center;
  padding: 20px;
  margin: 20px;
}

.portfolio-header {
  width: 80%;
}
