.homepage {
  display: flex;
  justify-content: center;
}

.homepage-container {
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.homepage-contents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.homepage-text-content {
  max-width: 500px;
  padding-right: 20px;
  padding-top: 20px;
  font-family: "Noto Serif";
  text-align: left;
}

.homepage-image {
  max-width: 400px;
  padding-top: 20px;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
}
