.student-film-page {
  text-align: center;
  font-family: "Bigshot One";
}

.student-film-page-text-container {
  max-width: 900px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.student-film-page-header {
  font-size: x-large;
}

.student-film-page-subheader {
  font-style: italic;
}
.student-film-page-subheader2 {
  font-size: large;
}
.student-film-page-blurb {
  padding-bottom: 40px;
}

.vimeo-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vimeo-btn {
  width: 200px;
  border-radius: 25px;
  margin-bottom: 40px;
}
