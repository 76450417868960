.about-page {
  display: flex;
  justify-content: center;
}

.about-page-container {
  max-width: 80vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 20px;
}

.about-page-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 500px;
  font-family: "Noto Serif";
  text-align: left;
}

.about-page-header {
  font-family: "Bigshot One";
  text-align: center;
}

.about-page-image {
  max-width: 400px;
  padding-left: 20px;
  padding-top: 20px;
}
