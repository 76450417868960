.contact-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-page-container {
  max-width: 80vw;
  padding-top: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.contact-page-content {
  max-width: 300px;
  font-family: "Noto Serif";
  text-align: left;
}

.contact-page-header {
  font-family: "Bigshot One";
}
