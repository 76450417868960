.site-header {
  display: flex;
  flex-direction: column;
  font-family: "Bigshot One";
  font-stretch: ultra-condensed;
}

.base-header {
  display: flex;
  min-width: 100vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  flex: 1;
  display: flex;
  justify-content: center;
}

.nav-item:first-child > span {
  margin-right: auto;
}

.nav-item:last-child > span {
  margin-left: auto;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

/* .link:hover {
  background-color: blueviolet;
} */

.title-container {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 4;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
