.portfolio-pages-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Bigshot One";
}

.portfolio-pages-footer-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}
