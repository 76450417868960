.text-scroll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  font-family: "Bigshot One";
}

.text-scroll-marquee {
  max-width: 900px;
}
