.site-footer {
  bottom: 10px;
  font-family: "Noto Serif";
  padding-top: 40px;
  padding-bottom: 10px;
}

.site-footer-link:hover {
  color: beige;
}
