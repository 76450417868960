.portfolio-card {
  position: relative;
  text-align: center;
  font-family: "Bigshot One";
}

.portfolio-card-image {
  width: 250px;
  height: 100%;
  border-radius: 25px;
}

.portfolio-card-overlay {
  border-radius: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 228, 196, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
}

.portfolio-card:hover .portfolio-card-overlay {
  opacity: 1;
}

@media (max-width: 700px) {
  .portfolio-card-overlay {
    opacity: 1;
  }
}
