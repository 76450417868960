.features-page {
  display: flex;
  max-width: 700px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: "Bigshot One";
}

.features-page-header {
  font-size: x-large;
}
.features-page-blurb {
  font-size: large;
  padding-bottom: 25px;
}

.features-page-link {
  padding: 20px;
  background-color: wheat;
  border-radius: 25px;
}

.features-page-link:hover {
  background-color: bisque;
}
