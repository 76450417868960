.off-binary-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80vw;
  text-align: center;
  font-family: "Bigshot One";
  padding: 20px;
}

.off-binary-page-header {
  font-size: x-large;
}

.off-binary-page-image {
  max-width: 350px;
  border-radius: 20px;
}

.off-binary-page-link-header {
  text-decoration: underline;
}
.off-binary-page-link {
  background-color: wheat;
  padding: 20px;
  border-radius: 25px;
  font-family: "Noto Serif";
}

.off-binary-page-link:hover {
  background-color: bisque;
}
