.portfolio-splash-page {
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.portfolio-splash-page-header {
  font-family: "Bigshot One";
  font-size: large;
  text-align: center;
  max-width: 80vw;
}

.portfolio-splash-page-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio-splash-page-card {
  padding: 10px;
}
